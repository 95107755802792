<template>
  <v-container>
    <v-row dense class="ma-2" v-for="(i,k) in items" :key="k">
        <v-col cols="2">{{i.faq }}</v-col>
        <v-col cols="2">{{i.assunto }}</v-col>
        <v-col cols="2">{{i.titulo }}</v-col>
        <v-col>{{i.descricao }}</v-col>
    </v-row>
    <pre>
        {{ items }}
    </pre>
  </v-container>
</template>
<script>
import api from '@/http'

const headers = [
    {text:'id', value:'id'},
    {text:'Assunto', width:350, value:'assunto'},
    {text:'Titulo', width:350, value:'titulo'},
    {text:'Descrição', value:'resume'},
]
    export default{
        name: 'Faq',
        data:()=>({
            items:[],
            headers:headers
        }),
        methods:{
            get_items(){
                api.get('faq/lista/')
                .then(res=>{
                    this.items = res.data
                })
            }
        },
        mounted(){
            this.get_items()
        }
    }
</script>